import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { 
    Menu as MenuIcon, 
    Task as TaskIcon, 
    GridView as GridIcon, 
    Pets as PetIcon, 
    EmojiEvents as TrophyIcon ,
    Museum as MuseumIcon,
    BugReport as BugIcon,
    Waves as SeaCreatureIcon,
    Sailing as FishIcon,
    Home as HomeIcon,
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';
import LogoImage from '../components/LogoImage';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const StyledSidebar = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 20rem;
`;

const CenteredLogo = styled.div`
    display: flex;
    justify-content: center;
    padding: 2em 0;
`;

const StyledLink = styled(Link)`
    cursor: pointer;
    transition: 0.15s;
    background-color: transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const tabs = [
    {
        name: "Overview",
        url: "/overview",
        icon: <GridIcon color="primary" />
    },
    {
        name: "Taskboard",
        url: "/taskboard",
        icon: <TaskIcon color="primary" />
    },
    {
        name: "Villagers",
        url: "/villagers",
        icon: <PetIcon color="primary" />
    },
    {
        name: "Home Goals",
        url: "/home-goals",
        icon: <HomeIcon color="primary" />
    },
    {
        name: "Island Goals",
        url: "/island-goals",
        icon: <TrophyIcon color="primary" />
    },
    {
        name: "Museum",
        url: "/museum",
        icon: <MuseumIcon color="primary" />,
        children: [
            {
                name: "Fish",
                url: "/museum/fish",
                icon: <FishIcon color="primary" />
            },
            {
                name: "Sea Creatures",
                url: "/museum/sea-creatures",
                icon: <SeaCreatureIcon color="primary" />
            },
            {
                name: "Bugs",
                url: "/museum/bugs",
                icon: <BugIcon color="primary" />
            }
        ]
    }
];

const Sidebar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [openCollapse, setOpenCollapse] = useState(-1);

    const handleCollapse = (index: number) => {
        if (openCollapse === index) {
            setOpenCollapse(-1);
        } else {
            setOpenCollapse(index);
        }
    };

    StyledLink.defaultProps = {
        theme: useTheme()
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <IconButton edge="start" color="inherit" onClick={toggleSidebar}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={isOpen} onClose={toggleSidebar}>
                <StyledSidebar>
                    <CenteredLogo>
                        <LogoImage width={5} height={5} />
                    </CenteredLogo>
                    <List>
                        {tabs.map((tab, index) => (
                            <>
                                {tab.children ? (
                                    <React.Fragment key={index}>
                                        <ListItem button onClick={() => handleCollapse(index)}>
                                            <ListItemIcon>
                                                {tab.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={tab.name} primaryTypographyProps={{ color: 'primary' }} />
                                            {openCollapse === index ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={openCollapse === index} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {tab.children.map((childTab, childIndex) => (
                                                    <ListItem key={childIndex} component={StyledLink} to={childTab.url}>
                                                        <ListItemIcon sx={{marginLeft: '1rem'}}>
                                                            {childTab.icon}
                                                        </ListItemIcon>
                                                        <ListItemText primary={childTab.name} primaryTypographyProps={{ color: 'primary' }} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                ) : (
                                    <ListItem key={index} component={StyledLink} to={tab.url}>
                                        <ListItemIcon>
                                            {tab.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={tab.name} primaryTypographyProps={{ color: 'primary' }} />
                                    </ListItem>
                                )}
                            </>
                        ))}
                    </List>
                </StyledSidebar>
            </Drawer>
        </>
    );
};

export default Sidebar;