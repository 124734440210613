import React from 'react';
import LogoImage from '../../assets/logo.png';
import styled from 'styled-components';

interface LogoProps {
    width: number;
    height: number;
}

const StyledImage = styled.img`
    border-radius: 10px;

`;

const Logo: React.FC<LogoProps> = ({ width, height }) => {
    const remWidth = `${width}rem`;
    const remHeight = `${height}rem`;

    return (
        <StyledImage src={LogoImage} alt="Logo" style={{ width: remWidth, height: remHeight }} />
    );
}

export default Logo;