import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/routes';
import Layout from './containers/Layout';
import { AuthProvider } from './contexts/AuthContext';
import { QueryClientProvider } from 'react-query';
import queryClient from './config/queryClient';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <Layout>
            <AppRoutes />
          </Layout>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
