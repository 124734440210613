import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

const SocialsDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: ${props => props.theme.palette.text.primary};
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const Socials: React.FC = () => {
    const theme = useTheme();
    
    return (
        <SocialsDiv theme={theme}>
            <StyledLink to="https://github.com">
                <GitHubIcon />
            </StyledLink>
            <StyledLink to="">
                <LinkedInIcon />
            </StyledLink>
            <StyledLink to="">
                <SportsBasketballIcon />
            </StyledLink>
        </SocialsDiv>
    );
};

export default Socials;