import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { useTheme } from '@mui/material/styles';

type LayoutProps = {
    children: ReactNode;
};

const Body = styled.section`
    padding: 1rem 4rem;
`;

const StyledMain = styled.main`
    min-height: 75vh;
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const theme = useTheme();
    
    return (
        <Body theme={theme}>
            <Header />
            <StyledMain>
                {children}
            </StyledMain>
            <Footer />
        </Body>
    );
};

export default Layout;