import React from 'react';
import Logo from '../components/Logo';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Sidebar from '../containers/Sidebar';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        align-items: center;
        gap: 20px;
    }
`;

const Header: React.FC = () => {
    const { currentUser, logout, loading } = useAuth();
    const navigate = useNavigate();

    const handleSignout = async () => {
        try {
            console.log('Signing out')
            await logout();
            // Perform addition logout-related actions
            navigate('/', { replace: true });
        }
        catch (error) {
            console.log("Error logging out:", error);
        }
    }
    
    return (
        <StyledHeader>
            <div>
                {currentUser ? <Sidebar /> : null}
                <Logo />
            </div>
            <div>
                {currentUser ? (
                    <>
                        <Button variant="contained" color="primary" component={Link} to="/account">Account</Button>
                        <Button variant="contained" color="secondary" onClick={() => handleSignout()}>Logout</Button>
                    </>
                ) : (
                    <>
                        <Button variant="contained" color="primary" component={Link} to="/login">Login</Button>
                        <Button variant="contained" color="secondary" component={Link} to="/signup">Sign Up</Button>
                    </>
                )}
            </div>
        </StyledHeader>
    );
};

export default Header;