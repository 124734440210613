import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LogoImage from '../LogoImage';
import Typography from '@mui/material/Typography';

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
`;

const Logo: React.FC = () => {
    return (
        <>
            <StyledLink to="/">
                <LogoImage width={5} height={5} />
                <Typography variant="h6" color="primary">Island Tracker</Typography>
            </StyledLink>
        </>
    );
};

export default Logo;