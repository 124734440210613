import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#393322',
        },
        secondary: {
            main: '#42B0CE',
        },
        background: {
            default: '#fff',
            paper: '#f5f5f5',
        },
        info: {
            main: '#6B654C',
        }
    },
    typography: {
        fontSize: 16,
        fontFamily: ['Seurat', 'Arial'].join(','),
        h1: {
            fontSize: '3rem',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
    }
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#E0F1FF',
        },
        secondary: {
            main: '#42B0CE',
        },
        background: {
            default: '#060A27',
            paper: '#011e4d',
        },
        info: {
            main: '#fff',
        }
    },
    typography: {
        fontSize: 16,
        fontFamily: ['Seurat', 'Arial'].join(','),
        h1: {
            fontSize: '3rem',
        },
        body2: {
            fontSize: '0.75rem'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#060A27',
                },
            },
        },
    }
});