import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Spinner from '../components/Spinner';

const Home = lazy(() => import('../views/Home'));
const Login = lazy(() => import('../views/Login'));
const Signup = lazy(() => import('../views/Signup'));
const Account = lazy(() => import('../views/account/Account'));
const Overview = lazy(() => import('../views/Overview'));
const Taskboard = lazy(() => import('../views/Taskboard'));
const Villagers = lazy(() => import('../views/Villagers'));
const IslandGoals = lazy(() => import('../views/IslandGoals'));
const Species = lazy(() => import('../views/museum/Species'));
const HomeGoals = lazy(() => import('../views/HomeGoals'));

const AppRoutes = () => {

    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route path="" element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />

                {/* Protected Routes */}
                <Route path="overview" element={
                    <ProtectedRoute>
                        <Overview />
                    </ProtectedRoute>
                } />
                <Route path="taskboard" element={
                    <ProtectedRoute>
                        <Taskboard />
                    </ProtectedRoute>
                } />
                <Route path="account" element={
                    <ProtectedRoute>
                        <Account />
                    </ProtectedRoute>
                } />
                <Route path="villagers" element={
                    <ProtectedRoute>
                        <Villagers />
                    </ProtectedRoute>
                } />
                <Route path="island-goals" element={
                    <ProtectedRoute>
                        <IslandGoals />
                    </ProtectedRoute>
                } />
                <Route path="museum/fish" element={
                    <ProtectedRoute>
                        <Species type="fish"  />
                    </ProtectedRoute>
                } />
                <Route path="museum/sea-creatures" element={
                    <ProtectedRoute>
                        <Species type="seacreatures"  />
                    </ProtectedRoute>
                } />
                <Route path="museum/bugs" element={
                    <ProtectedRoute>
                        <Species type="bugs"  />
                    </ProtectedRoute>
                } />
                <Route path="home-goals" element={
                    <ProtectedRoute>
                        <HomeGoals />
                    </ProtectedRoute>
                } />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;