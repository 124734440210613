import React from 'react';
import Logo from '../components/Logo';
import Socials from '../components/Socials';
import styled from 'styled-components';

const StyledFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        align-items: center;
        gap: 20px;
    }
`;

const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <Logo />
            <Socials />
        </StyledFooter>
    );
};

export default Footer;