import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { ThemeCtxProvider, useThemeMode } from './contexts/ThemeContextProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme, darkTheme } from './themes/themes';

const Root = () => {
  const { darkMode } = useThemeMode();
  let theme = React.useMemo(() => {
    return createTheme(!darkMode ? darkTheme : lightTheme);
  }, [darkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeCtxProvider>
      <Root />
    </ThemeCtxProvider>
  </React.StrictMode>
);

reportWebVitals();
